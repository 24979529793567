import Vue from 'vue';
import App from './App.vue';
import VueRouter from 'vue-router';
import router from './router';
Vue.use(VueRouter);

import Bagel from '@bageldb/bagel-db';
const $db = new Bagel(process.env.VUE_APP_BAGEL_TOKEN);

Vue.prototype.$db = $db;

Vue.config.productionTip = false;

new Vue({
  router,
  render: (h) => h(App),
}).$mount('#app');
