<template >
    <div class="page-wrap">
        <div class="hero">
            <div class="hero-flex">
                <div class="hero-txt-wrap"><img
                        src="../assets/jol-logo.svg"
                        class="logo"
                    />
                    <h2 class="subtitle">Every life is valuable. At Just One Life, we support and empower families in crisis to help each mother and child thrive.</h2>
                </div>
                <div class="hero-image-wrap">
                    <img
                        src="../assets/hero.png"
                        class="hero-img"
                    />
                    <div class="back-image"></div>
                    <div class="smaller-box"></div>
                </div>
            </div>
        </div>
        <div class="mid-sec">
            <p class="txt">Our team serves women experiencing physical, emotional, and psychological
                crises by imparting practical skills, emotional support, and financial aid. With the
                help of our donors, each mother and child overcome insurmountable challenges
                and emerge happy, healthy, and whole. </p>
            <div class="blue-txt-wrap">
                <div class="pink-line"></div>
                <p class="blue-txt">Just One Life needs the support of donors like you to help save each precious life. </p>
                <div class="pink-line"></div>
            </div>
            <a
                href="https://justonelife.org/donate"
                target="_blank"
                class="btn"
            >Donate today</a>
        </div>
        <div v-if="story.length">
            <carousel
                :nav="false"
                :dots="true"
                :autoWidth="true"
                :autoplay="true"
                :loop="true"
                :margin=30
            >

                <a
                    class="slide"
                    v-for="slide in story"
                    :key="slide._id"
                    :href="`https://justonelife.org/story/${slide.Slug}`"
                    target="_blank"
                >

                    <p class="slide-small-txt">The Story of</p>
                    <p class="slide-txt">{{ slide.title }}</p>
                    <img
                        class="slide-img"
                        :src="slide.image.imageURL"
                        :alt="slide.title"
                    >

                </a>
            </carousel>
        </div>



    </div>
</template>




<script>
import carousel from "sh-v-owl-carousel";
export default {
    components: {
        carousel,
    },
    data() {
        return {
            story: {},
        };
    },
    async beforeMount() {
        let { data: story } = await this.$db
            .collection("story")
            .get();
        this.story = story;
    },
    name: 'Home'
};
</script>
